import React from 'react'
import Fade from 'react-reveal/Fade';

export default function HomeMissionAndVision() {
    return <Fade>
    <section className="text-center py-5 shadow ">
        <h2 className="text-capitalize">Misson</h2>
        <p className="container">
        We at AIC are devoted to contributing and serving the scientific community across the country by being a one stop solution for all the laboratory requisites. Good quality products, a diversified product range, customer satisfaction, on time deliveries and value addition to the stakeholders associated with us, has been our strength for the past six decades and we will continue to build on the same in the years to come. “Serving the Science” is our moto and are proud to be a part of the same
        </p>
    </section>
</Fade>
}

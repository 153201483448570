import React from 'react'

export default function HeaderLogo() {
    return <a href="/">
        <img
            style={HeaderLogoImageStyle}
            src="/assets/images/aic-logo.webp"
            alt="AIC Logo" />
    </a>
}

const HeaderLogoImageStyle = {
    maxHeight: '120px',
    borderRadius: '50%'
}

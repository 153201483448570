import React from 'react'
import Fade from 'react-reveal/Fade';

export default function ProductAuthorizedDealers() {
    return <div className="row my-4">
        {dealers.map((dealer, idx) => <Fade key={idx}>
            <div className="col-md-6 pb-3" key={idx}>
                <div className="card h-100">
                    <div className="card-body">
                        <h4>{dealer.title}</h4>
                        <ul>
                            {dealer.child.map((child, childIdx) => <li key={childIdx}>{child}</li>)}
                        </ul>
                    </div>
                </div>
            </div>
        </Fade>
        )}
    </div>
}

const dealers = [
    {
        "title": "Lab Chemicals",
        "child": [
            "CDH - Range of chemicals",
            "MERCK – Imported and Indian range of chemicals",
            "SRL  - Range of chemicals",
            "SPECTRUM -  Range of chemicals"
        ]
    },
    {
        "title": "Lab Glasswares",
        "child": [
            "SCHOTT – DURAN - brand of Glasswares",
            "RIVIERA - brand of Glasswares",
            "MERCK – ACTIRA - range of Glasswares"
        ]
    },
    {
        "title": "Lab Plasticwares",
        "child": [
            "POLYLAB - range of Plasticwares",
            "MBP – range of research grade Plasticwares",
            "AXIVA – range of Plasticwares"
        ]
    },
    {
        "title": "Filteration",
        "child": [
            "AXIVA – range of filerwares",
            "NUPORE – range of Laboratory filtrations",
            "WHATMAN – range of filtration products",
            "MERCK – MILLIPORE – range of products for Bio & Lab process",
            "3M – Purifications Inc (CUNO) – products for Pharma & Bioprocess"
        ]
    },
    {
        "title": "Liquid Handling",
        "child": [
            "POLYLAB",
            "AXIVA",
            "RIVIERA",
            "FINNPIPPETTE",
            "MBP"
        ]
    },
    {
        "title": "Life Science",
        "child": [
            "NUNC – range of Cell Culture",
            "NALGENE -  range of General Labwares",
            "ABGENE – range of PCR plasticwares",
            "FISHER BIOREAGENT – Molecular Biology grade Chemicals",
            "FINNZYMES – range of PCR master mix",
            "SOLARIS – qPCR master mix",
            "SRL – Range of molecular biology grade chemicals"
        ]
    },
    {
        "title": "Instruments",
        "child": [
            "RIVOTEK – range of Instruments",
            "EUTECH – range of Instruments"
        ]
    },
    {
        "title": "Clean Room",
        "child": [
            "VELTEK – USA -  Clean room solutions"
        ]
    },
    {
        "title": "Chromatography",
        "child": [
            "THERMO SCIENTIFIC - Range of columns and consumables"
        ]
    }
]

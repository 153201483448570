import React from 'react'
import { MdPhone, MdMail } from "react-icons/md";
import { FaMapMarkedAlt } from "react-icons/fa";

import PageContainer from '../base/PageContainer'

import './FootContainer.css'

export default function FootContainer() {
    return <PageContainer className="py-5 footer">
        <div className="container">
            <div className="row">
                <div className="col-md-3">
                    <h3>Quick Links</h3>
                    <ul>
                        <li><a href="/">About us</a></li>
                        <li><a href="/">Products</a></li>
                    </ul>
                </div>

                <div className="col-md-3">
                    <h3>Legals</h3>
                    <ul>
                        <li><a href="/">Privacy Policy</a></li>
                        <li><a href="/">Disclaimer</a></li>
                        <li><a href="/">Terms & Condition</a></li>
                    </ul>
                </div>

                <div className="col-md-3">
                    <h3>Contact us</h3>
                    <nav>
                        <a href="tel:+919494949494"> <MdPhone /> </a>
                        <a href="mailto:bangalore@aicgroup.in?subject=Website Query"> <MdMail /> </a>
                        <a href="https://www.google.com/maps/dir//AIC+Enterprises,+V79A+%26+V79B,+1st+A+Main,+2nd+Stage,+Peenya,+Bengaluru,+Karnataka+560058/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3bae16637d53daf5:0x2abd4f01f85b7990?sa=X&ved=0ahUKEwidz-nZlo_mAhXPyjgGHZKQDQAQ48ADCEswAQ"> <FaMapMarkedAlt /> </a>
                    </nav>
                </div>
            </div>
        </div>
    </PageContainer>
}

import React, { useContext } from 'react'
import HeaderSearch from './HeaderSearch';
import HeaderMenuNavigation from './HeaderMenuNavigation';
import listContext from '../../../context/product.context';
import { Link } from 'react-router-dom';

export default function HeaderMenu(props) {
    const { cartCount } = useContext(listContext);
    return (
        <div className="header__menu ml-3">
            <HeaderSearch />
            <div className="d-flex align-items-center mb-3 mt-4">
                <HeaderMenuNavigation />
                <button className="btn btn-primary ml-auto" data-toggle="modal" data-target="#modal__enquiry">Enquire now</button>
                <Link to="/cart" className="ml-5 nounderline"><i className="fas fa-shopping-cart "></i><span className="card-count">{cartCount}</span> Cart</Link>
                {/* <a>Quick Order<i class="fas fa-shopping-cart"></i></a> */}
            </div>
        </div>
    )
}

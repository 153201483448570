import React, { useContext, useEffect } from 'react';
import { useForm } from "react-hook-form";
import listContext from '../context/product.context';
import { Redirect, useHistory } from 'react-router-dom';
import HeaderLogo from '../components/header/components/HeaderLogo';

const LoginContainer = (props) => {
    const context = useContext(listContext);
    const { loginSubmit, token } = context;
    const { handleSubmit, register, errors } = useForm();
    const history = useHistory();
    const onSubmit = values => {
        loginSubmit(values)
        history.push("/home");
    }
    console.log({ errors });


    // if (token) {
    //     return <Redirect to='/home' />
    //     // window.location.pathname = '/home';

    //     // history.push('home')
    // }
    return <div className="d-flex">
        <div className="card login_card">
            <img className="login_image card-img-top" src="/assets/images/home-sliders/slider-7.webp" />
            <div className="card-img-overlay">
                <h2 className="card-title card-body text-center align-middle welcome_text">Welcome to AIC Groups</h2>
            </div>
        </div>
        <div className="d-flex">
            <div className="container login">
                <div className="row justify-content-center">
                    <div className="col-12  py-3">
                    <div className="d-flex justify-content-center">
                        <a href="/">
                            <img
                               className="logo_image"
                                src="/assets/images/aic-logo.webp"
                                alt="AIC Logo" />
                        </a>
                        </div>
                        <h2 className="text-center mt-5 text-primary">Login</h2>

                        <form className="my-3" onSubmit={handleSubmit(onSubmit)} noValidate>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input name="email" type="email" className="form-control" id="email" ref={register({
                                    required: "Required",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "invalid email address"
                                    }
                                })} />
                                {errors.email && <span className="text-danger">Email is required</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input name="password" type="password" className="form-control" id="password" ref={register({
                                    required: "Required"
                                })} />
                                {errors.password && <span className="text-danger">Passward is required</span>}
                            </div>

                            <div className="form-group mt-4">
                                <button className="btn btn-primary btn-block" type="submit">Login</button>
                            </div>
                        </form>
                        <div className="form-group mt-4">
                            <a href="/signUp" className="nounderline"><div className="" >Create account</div></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default LoginContainer;
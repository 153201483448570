import React from 'react';
import PageContainer from '../../../components/base/PageContainer';
import Fade from 'react-reveal/Fade';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
export default function HomeClients() {
    return <PageContainer className="py-5 text-center shadow">
        <h2>Our Clients</h2>
        <Fade left>
            <Carousel
                slidesPerPage={3}
                autoPlay={2000}
                animationSpeed={1000}
                arrows
                infinite
            >
                {sliderImages.map((sliderImage, idx) => <div key={idx} className="p-3 border"><img alt={sliderImage.name} src={sliderImage.image} /></div>)}
            </Carousel>
        </Fade>
    </PageContainer>
}
// const sliderImages = (new Array(6).fill(1)).map((item, idx) => `/assets/images/ourclients-sliders/slider-${idx + 1}.png`)
// console.log({ sliderImages })
const sliderImages = [
    {
        'name': 'TUV',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/1.png"
    },
    {
        'name': 'Strides',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/2.png"
    },
    {
        'name': 'Syngene',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/3.png"
    },
    {
        'name': 'Males',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/4.png"
    },
    {
        'name': 'Recipharm',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/5.png"
    },
    {
        'name': 'Mylan',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/6.png"
    },
    {
        'name': 'Ncbs',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/7.png"
    },
    {
        'name': 'Medreich',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/8.png"
    },
    {
        'name': 'Micro',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/9.png"
    },
    {
        'name': 'Lotus',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/10.png"
    },
    {
        'name': 'Loreal',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/11.png"
    },
    {
        'name': 'Itc',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/12.png"
    },
    {
        'name': 'IIOS',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/13.png"
    },
    {
        'name': 'Cftri',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/14.png"
    },
    {
        'name': 'Himalaya',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/15.png"
    },
    {
        'name': 'Anthem',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/16.png"
    },
    {
        'name': 'Biocon',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/17.png"
    },
    {
        'name': 'Apotex',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/18.png"
    },
    {
        'name': 'Novozymes',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/19.png"
    },
    {
        'name': 'Zumutor',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/20.png"
    },
    {
        'name': 'Sun',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/21.png"
    },
    {
        'name': 'Eurofins',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/22.png"
    },
    {
        'name': 'Jubilant',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/23.png"
    },
    {
        'name': 'GVK',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/24.png"
    },
    {
        'name': 'IBL',
        'description': "The TUV300 is the compact SUV by the Indian automaker Mahindra & Mahindra. The TUV300 boasts monocoque construction. It competes with the likes of Ford EcoSport and Maruti Suzuki Vitara Brezza among others.",
        'image': "/assets/images/ourclients-sliders/25.png"
    }
]
import React, { useContext } from 'react'
import listContext from '../../../context/product.context';

const ProductTable = (props) => {
    const { products = [] } = props;

    const context = useContext(listContext);
    const { addToCart, cart = [] } = context;
    return (<table className="table table-bordered table__products">
        <thead>
            <tr>
                <th>Code</th>
                <th>Catalog</th>
                <th>Description</th>
                <th>Features</th>
                <th>Packaging</th>
                <th>Physical properties</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {products.map((product, index) => <tr key={index}>
                <td>{product.code}</td>
                <td>{product.name}</td>
                <td>{product.description}</td>
                <td>{product.features}</td>
                <td>{product.packaging}</td>
                <td>{product.physical_properties}</td>
                <td style={{minWidth: "140px"}}>
                    {cart.map(p => p.code).indexOf(product.code) === -1 ? <button
                        className="btn btn-primary btn-sm"
                        onClick={() => addToCart(product)}>
                        Add to cart
                                </button> : <React.Fragment>
                            <strong>{cart.filter(p => p.code === product.code)[0].count} item(s) added</strong>
                            <div className="mt-3">
                                <button className="btn btn-sm btn-success mr-2  py-0 px-2" onClick={() => addToCart(product)}>+</button>
                                <span>{cart.filter(p => p.code === product.code)[0].count}</span>
                                <button className="btn btn-sm btn-danger ml-2 py-0 px-2" onClick={() => addToCart(product, false)}>-</button>
                            </div>
                        </React.Fragment>
                    }
                </td>
            </tr>
            )}
        </tbody>
    </table>
    )
}

export default ProductTable;
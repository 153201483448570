import React from 'react'
import Fade from 'react-reveal/Fade';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
export default function HomeSlider() {
    return <Fade left>
        <Carousel
            slidesPerPage={3}
            autoPlay={2000}
            animationSpeed={1000}
            arrows
            infinite
        >
            {sliderImages.map((image, idx) => <div key={idx} className="p-3 border"><img alt={"Slider " + (idx + 1)} src={image}  /></div>)}
        </Carousel>
    </Fade>
}

const sliderImages = (new Array(10).fill(1)).map((item, idx) => `/assets/images/home-sliders/slider-${idx + 1}.webp`)
console.log({ sliderImages })
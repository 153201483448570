import React from 'react'
import PageContainer from '../../../components/base/PageContainer'
import AddProductInputField from './AddProductInputField';

export default function AddProductForm() {
    const productFields=productField[0];
    const map = new Map(Object.entries(productFields));
    console.log(map);
    return <PageContainer className="px-5">
    <div className="col-6 container card my-4 p-0">
    <div className="card-header"><h3 className="font-weight-bold  ">Add Product</h3></div>
    <div className="addProductForm group-flush p-4">
  <form>
    <AddProductInputField  name="Name"/>
    <AddProductInputField  name="Code"/>
    <AddProductInputField  name="Brand"/>
    <AddProductInputField  name="Pack"/>
    <AddProductInputField  name="Volume"/>
    <AddProductInputField  name="Color"/>
    <AddProductInputField  name="Quantity"/>
    <AddProductInputField  name="Description"/>
    <div className="col-12 form-group text-center my-3">
        <button className="btn btn-outline-primary shadow px-5 btn-lg">Submit</button>
        </div>
    </form>
    </div>
    </div>
    </PageContainer>
}

const productField=[
    { 
    id: 1, name: '',code:'', hsn_code:'', hsn_sac:'', brand:'', pack:'', description:'',catelog_id:'cat001',division_code:'GenaxyDiv001',sku_id:'SKU001',price:12.34,catalog:'',volume:'',color:'',packaging:'',type:'',quantity:'' 
   }
];
import React ,{ useContext,useState,useEffect } from 'react';
import { useForm } from "react-hook-form";
import listContext from '../../../context/product.context';
import { Redirect, useHistory } from 'react-router-dom';


const ProceedContainer = (props) => {
    const context = useContext(listContext);
    const {sendOrders } = context;
    const[checked,setChecked] = useState(false);
    const address="";
    const address2=""
    const state=""
    const zip=""
    const country=""
    const mobileNumber=""
    const { handleSubmit, register, errors, setValue,watch} = useForm();
    const watchAddress = watch("address", address);
    const watchAddress2 = watch("address2", address2);
    const watchState = watch("state", state);
    const watchZip = watch("zip", zip);
    const watchCountry = watch("country", country);
    const watchMobileNumber = watch("mobileNumber", mobileNumber);
    const checkbox = watch("checkbox", address);
    const history = useHistory();
    // const checkbox = watch("checkbox", );
    const onSubmit = values => {
      console.log('values',values)
        const billingAddress= (values.address)+','+( values.address2)+','+
        (values.state)+','+(values.zip)+','+(values.country)+','+
        (values.mobileNumber);
        const shippingAddress= (values.address_shipping)+','+( values.address2_shipping)+','+
        (values.state_shipping)+','+(values.zip_shipping)+','+(values.country_shipping)+','+
        (values.mobileNumber_shipping);
        // console.log('billingAddress',billingAddress)
      
        sendOrders(billingAddress,shippingAddress);
        history.push("/home");
    }
    const onChange = (event) => {
      if (event.target.checked) {
        setValue("address_shipping", watchAddress);
        setValue("address2_shipping", watchAddress2);
        setValue("state_shipping", watchState);
        setValue("zip_shipping", watchZip);
        setValue("country_shipping", watchCountry);
        setValue("mobileNumber_shipping", watchMobileNumber);
        setChecked(true);
      } else {
        setValue("address_shipping", "");
        setChecked(false);
      }
    };
    React.useEffect(() => {
      if (checkbox) {
        setValue("address_shipping", watchAddress);
        setValue("address2_shipping", watchAddress2);
        setValue("state_shipping", watchState);
        setValue("zip_shipping", watchZip);
        setValue("country_shipping", watchCountry);
        setValue("mobileNumber_shipping", watchMobileNumber);
      }
    }, [watchAddress]);
    console.log({ errors })
    return <section >
            <form className="needs-validation" onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="d-flex mt-4 mb-4">
            <div className="container login">
             <h4 className="my-3">Billing address</h4>
                <div className="my-3">
                    <label htmlFor="address">Address</label>
                    <input type="text" name="address" className="form-control" id="address" placeholder="1234 Main St" 
                    ref={register({
                        required: "Please enter your shipping address"
                      })}
                    />
                    {errors.address && <span className="text-danger">{errors.address.message}</span>}
                </div>
                <div className="mb-3">
                    <label htmlFor="address2">Address 2 </label>
                    <input type="text" name="address2" className="form-control" id="address2" placeholder="Apartment or suite" 
                    ref={register({
                      required: "Please enter your shipping address"
                    })}
                    />
                </div>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <label htmlFor="state">State</label>
                        <select className="custom-select d-block w-100" name="state" id="state" 
                        ref={register({
                            required: "Please provide a valid state",
                          })}
                        >
                            <option value="">Choose...</option>
                            <option>Hyderabad</option>
                        </select>
                        {errors.state && <span className="text-danger">{errors.state.message}</span>}
                    </div>

                </div>
                <div className="row">
                <div className="col-md-12 mb-3">
                <label htmlFor="zip">Zip</label>
                <input type="text" name="zip" className="form-control" id="zip" placeholder="" 
                ref={register({
                    required: "Zip code required",
                  })}
                />
                {errors.zip && <span className="text-danger">{errors.zip.message}</span>}
            </div>
                </div>
                <div className="mb-3">
                <div className="form-group">
               <label htmlFor="mobileNumber">Mobile Numaber</label>
               <input
                 name="mobileNumber"
                 placeholder="99393932"
                 className="form-control"
                 ref={register({
                   required: "Mobile Number is required",
                   minLength: {
                     value: 2,
                     message: "Min length is 2"
                   }
                 })}
               />
               {errors.mobileNumber && <span className="text-danger">{errors.mobileNumber.message}</span>}
               </div>
            </div>
                <hr className="mb-4"/>
                <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" name="same-address" id="same-address"  onChange={onChange} />
                    <label className="custom-control-label" htmlFor="same-address">Shipping address is the same as my billing address</label>
                </div>
                </div>
                 <div className="container login">
                <div className="col-md-12 order-md-1">
                    <h4 className="my-3">Shipping address</h4>
                        <div className="mb-3">
                            <label htmlFor="address_shipping">Address</label>
                            <input type="text" name="address_shipping" className="form-control" id="address" placeholder="1234 Main St" 
                            ref={register({
                                required: "Please enter your shipping address",
                              })}
                            />
                            {errors.address_shipping && <span className="text-danger">{errors.address_shipping.message}</span>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="address2_shipping">Address 2 </label>
                            <input type="text" name="address2_shipping" className="form-control" id="address2" placeholder="Apartment or suite" 
                            ref={register({
                              required: "Please enter your shipping address",
                            })}
                            />
                        </div>
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <label htmlFor="state_shipping">State</label>
                                <select className="custom-select d-block w-100" name="state_shipping" id="state" 
                                ref={register({
                                    required: "Please provide a valid state",
                                  })}
                                >
                                    <option value="">Choose...</option>
                                    <option>Hyderabad</option>
                                </select>
                                {errors.state_shipping && <span className="text-danger">{errors.state_shipping.message}</span>}
                            </div>
        
                        </div>
                        <div className="row">
                        <div className="col-md-12 mb-3">
                        <label htmlFor="zip_shipping">Zip</label>
                        <input type="text" name="zip_shipping" className="form-control" id="zip" placeholder="" 
                        ref={register({
                            required: "Zip code required",
                          })}
                        />
                        {errors.zip_shipping && <span className="text-danger">{errors.zip_shipping.message}</span>}
                    </div>
                        </div>
                      <div className="mb-3">
                        <div className="form-group">
                       <label htmlFor="mobileNumber_shipping">Mobile Numaber</label>
                       <input
                         name="mobileNumber_shipping"
                         placeholder="99393932"
                         className="form-control"
                         ref={register({
                           required: "Mobile Number is required",
                           minLength: {
                             value: 2,
                             message: "Min length is 2"
                           }
                         })}
                       />
                       {errors.mobileNumber_shipping && <span className="text-danger">{errors.mobileNumber_shipping.message}</span>}
                       </div>
                   </div>
                        <hr className="mb-4" />
                        <div className="pb-4"><button className="btn btn-primary btn-lg btn-block mb-4" type="submit">Continue to checkout</button></div>
                </div>
                </div>
        
               
                </div>      
            </form>
    </section>
}

export default ProceedContainer;
import React,{useContext} from 'react'
import { FiSearch } from "react-icons/fi";
import listContext from '../../../context/product.context';
import { useHistory } from 'react-router-dom';

export default function HeaderSearch(props) {
    const context = useContext(listContext);
    const {token,handleLogout,refreshToken } = context;
    const history = useHistory();
    const handleClick =()=>{
        handleLogout(refreshToken);
        history.push("/home");
    }
    const getToken = () => localStorage.getItem('TOKEN_KEY');
    // useEffect(() => {
    //     getToken();
    //   }, []);
    
    return <div>
            <div className="ml-5 mb-2 d-flex justify-content-end loginContainer ">
             {getToken()?<a onClick={handleClick} href={null} className="signOut nounderline ml-3 px-5">Sign out</a>:
             <a href="/login"  className="nounderline "><span className=" ml-3 px-5">Sign in</span></a>    
        }
        </div>
    <div className="input-group header__search">
        <div className="input-group-prepend">
            <label className="input-group-text"><FiSearch /></label>
        </div>
        <input className="form-control" placeholder="what are you looking for..." />

    </div>
    </div>
}

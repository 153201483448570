import React from 'react'
import PageContainer from '../../components/base/PageContainer'
import ContactLocations from './components/ContactLocations'

import './ContactContainer.css'
import ContactForm from './components/ContactForm'

export default function ContactContainer() {
    return <PageContainer>
        <div className="row">
            <ContactLocations />
            <ContactForm />
        </div>
    </PageContainer>
}

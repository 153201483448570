import React, { useState } from 'react';
import listContext from './product.context';
import { signInApi,registerApi, logoutApi, currentUserApi,sendOrderApi,httpClientAuth } from '../services/api.services';

//global store for our app we can create multiple contexts if needed

function Store({ children }) {
    const location=window.location.pathname;
    const previousCart = JSON.parse(localStorage.getItem("__CART__") || "{}");
    const { cart = [], cartCount = 0 } = previousCart || {};
    // const {token, refreshToken ,email ,username}= {};
    const [token, setToken]= useState(null);
    const [refreshToken , setRefreshToken] = useState(null)
    const [path , setPath] = useState(location);
    const [message , setMessage] = useState(null);
    const [error , setError] = useState(null);
    const [email , setEmail] = useState(null);
    const [userName , setUserName] = useState(null);
    const isAuthenticated = () => localStorage.getItem('TOKEN_KEY') !== null;
    const login = (token,refreshToken) => {
      localStorage.setItem('TOKEN_KEY', token);
      localStorage.setItem('REFRESH_TOKEN', refreshToken);
    };
    const logout = () => {
    localStorage.removeItem('TOKEN_KEY');
    };
    
    const getRefreshToken = () => localStorage.getItem('REFRESH_TOKEN');
    const getToken = () => localStorage.getItem('TOKEN_KEY');
    
    console.log({ cart, cartCount })
    console.log({ token, refreshToken })
    // the app's initial state
    const initialState = {
        cart,
        cartCount,
        token,
        refreshToken,
        addToCart: addToCart,
        removeFromCart: removeFromCart,
        loginSubmit: loginSubmit,
        signUpSubmit:signUpSubmit,
        handleLogout: handleLogout,
        getCurrentUser: getCurrentUser,
        path,message,error,
        sendOrders:sendOrders,

    }

    ////// add new product to cart and update cart count

    function addToCart(product, increment = true) {
        let newList = appState.cart;

        const newItem = {
            count: 1,
            code: product.code,
            product,
        }

        const filtered = newList.filter(i => {
            return i.code === product.code;
        });

        /* if the product is already in the cart,
        update it's count otherwise add it to cart with 1 count */


        if (filtered.length > 0) {
            const pos = newList.map(i => { return i.code; }).indexOf(product.code);
            if (increment) {
                newList[pos].count += 1;
            } else {
                newList[pos].count -= 1;
                if (newList[pos].count <= 0) {
                    removeFromCart(pos);
                    return false;
                }
            }
        } else {
            newList.push(newItem);
        }
        setState({ ...appState, cart: newList, cartCount: getCartCount() });

    }

    ////// remove product from cart and update cart count
    function removeFromCart(indx) {
        const cartList = appState.cart;
        cartList.splice(indx, 1);
        setState({ ...appState, cart: cartList, cartCount: getCartCount() });
    }

    ////// function to set the number of products in cart

    function getCartCount() {
        let cnt = 0;
        if (appState.cart.length > 0) {
            appState.cart.forEach(item => {
                cnt += item.count;
            });
        }
        return cnt;
    }

    //Login loginSubmit
    function loginSubmit(data){
     signInApi
     .create(data)
     .then(res => res.data)
     .then(({token,refreshToken}) => {
        login(token,refreshToken);
        setToken(token);
        setRefreshToken(refreshToken);
        setState({ ...appState, token,refreshToken,path:'/home' });
    })
    .catch(error => {
        console.log(error.response.data.error);
        setState({ ...appState, message:error.response.data.error, error:error.response.data.error });
       })
    }

       //Sign up submit
    function signUpSubmit(data){
        console.log('value',data,registerApi);
        registerApi
        .create(data)
        .then(res => res.data)
        .then(({message}) => {
           console.log('message',message);
           setState({ ...appState,message:setMessage(message), path:'/login'})

       })
       }

     //Signout submit
    function handleLogout(refreshToken){
        console.log('refreshToken',refreshToken);
        const payload={"refresh_token":refreshToken};
        logoutApi
        .create(payload)
        .then(res => res.data)
        .then(({status}) => {
           console.log('status',status);
           logout();
           localStorage.clear();
           localStorage.setItem("__CART__", JSON.stringify(""));
        //    JSON.parse(localStorage.getItem("__CART__") || "{}");
           setState({ ...appState, cart: [], cartCount: 0,path:'/home' });
       })
       }
     //get current User submit
    function getCurrentUser(onSuccess){
        const payload=getToken();
        httpClientAuth(payload)
        .get('/me')
        .then(res => res.data)
        .then(({email,username}) => {
            onSuccess({email,username})
           console.log('emailemail',username);
           setState({ ...appState,email:email,userName:username})
       })
       }
     //submit order with shipping and billing address
    function sendOrders(billingAddress,shippingAddress){
       getCurrentUser(onSuccess =>{
        const products=cart.map(e=>e.product);
       
        const payload ={
            "email":onSuccess.email,
            "order_owner_name": onSuccess.userName,
            "order_receiver_name": onSuccess.userName,
            "shipping_address": shippingAddress,
            "billing_address": billingAddress,
            "amount": "300",
            "taxi": "30",
            "vat": "0",
            "products":products,
            "prepaid_amount": "30",
            "order_json":null,
            "total_amount": "130",
            
        };
        console.log('payload',payload)
        sendOrderApi
        .create(payload)
        .then(res => res.data)
        .then(({message}) => {
           console.log('message',message);
       localStorage.setItem("__CART__", JSON.stringify(""));
       setState({ ...appState, cart: [], cartCount: 0,message:message, path:'/home' });
       })
        })
       }
    //initiate app state with initialstates

    const [appState, setState] = useState(initialState);
    React.useEffect(() => {
        const { cart, cartCount } = appState;
        console.log('appState',appState)
        localStorage.setItem("__CART__", JSON.stringify({ cart, cartCount }))
    }, [appState])

    return (
        <listContext.Provider value={appState}>
            {children}
        </listContext.Provider>
    )
}

export default Store;
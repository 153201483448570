import React from 'react'

export default function ContactForm() {
    return <form className="col p-4">
        <div className="row">
            <div className="col-12 form-group">
                <label>Name</label>
                <input className="form-control" placeholder="Full name" />
            </div>

            <div className="col-6 form-group">
                <label>Phone number</label>
                <input type="number" className="form-control" placeholder="+91 9676222172" />
            </div>

            <div className="col-6 form-group">
                <label>Email</label>
                <input type="email" className="form-control" placeholder="sample@company.com" />
            </div>

            <div className="col-6 form-group">
                <label>Company</label>
                <input className="form-control" placeholder="Company name" />
            </div>

            <div className="col-6 form-group">
                <label>CAS Number of Product</label>
                <input className="form-control" placeholder="#9676222172" />
            </div>

            <div className="col-12 form-group">
                <label>Product Description</label>
                <textarea className="form-control" placeholder="Specify product description"></textarea>
            </div>

            <div className="col-12 form-group">
                <label>Address</label>
                <textarea className="form-control" placeholder="Address details"></textarea>
            </div>

            <div className="col-12 form-group">
                <label>Pincode</label>
                <input type="number" className="form-control" placeholder="500081" />
            </div>

            <div className="col-12 form-group text-center my-3">
                <button className="btn btn-outline-primary shadow px-5 btn-lg">Submit</button>
            </div>
        </div>
    </form>
}

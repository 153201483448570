import React from 'react'
import PageContainer from '../../components/base/PageContainer'
import AddProductForm from './components/AddProductForm'



export default function AddProduct() {
console.log('AddProduct')
    return <PageContainer > 
    <div className="row">
            <AddProductForm/>
        </div>
    </PageContainer>    
}
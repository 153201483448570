import React from 'react'
import ContactForm from '../../../containers/contact/components/ContactForm'

export default function HeaderEnquiryModal() {
    return <div className="modal fade" id="modal__enquiry" >
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" >Enquiry Form</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body pt-0">
                    <div className="row">
                        <ContactForm />
                    </div>
                </div>

            </div>
        </div>
    </div>
}

import React from 'react'
import PageContainer from '../../components/base/PageContainer'
import ProductsList from './components/ProductsList'
import ProductAuthorizedDealers from './components/ProductAuthorizedDealers'

import { brandsApi } from '../../services/api.services';

export default function ProductsContainer() {

    const [products, assignProducts] = React.useState([]); // -> [value, valueSetterFunction];
    /**
     *  const numbers = [1,2,3];
     *  const [one, two] = numbers;
     */

    React.useEffect(() => {
        brandsApi
            .index()
            .then(res => res.data)
            .then(products => {
                // console.log(":: ProductsContainer ::", products)
                assignProducts(products);
                localStorage.setItem("PRODUCTS", JSON.stringify(products));
            })
    }, [])

    return <PageContainer className="py-5">
        <div className="container">
            <h2 className="text-primary mb-4">Products</h2>
            <ProductsList products={products} />
            <ProductAuthorizedDealers />
        </div>
    </PageContainer>
}

import React ,{ useContext,useState,useEffect } from 'react';
import { useForm } from "react-hook-form";
import listContext from '../context/product.context';
import Select from "react-select";
import {
 useHistory 
} from "react-router-dom";

const SignUpContainer = (props) => {
  const history = useHistory();
    const context = useContext(listContext);
    const {signUpSubmit } = context;
    const { handleSubmit, register, errors, setValue} = useForm();
    const [values, setReactSelect] = useState({
        selectedOption: []
      });
    const onSubmit = values => {
        console.log(values);
        signUpSubmit(values);
        history.push("/login");
    }
    const handleSelectChange = selectedOption => {
        console.log('selectedOption',selectedOption)
        setValue("reactSelect", selectedOption);
        setReactSelect({ selectedOption });
      };
    console.log({ errors })
    const options = [
        { value: "admin", label: "Admin" },
        { value: "agent", label: "Agent" },
        { value: "customer", label: "Customer" }
      ];
      useEffect(() => {
        register({ name: "reactSelect" });
      }, [register]);
    
    return <div className="d-flex">
      <div className="card login_card">
        <img className="login_image card-img-top" src="/assets/images/home-sliders/slider-7.webp" />
        <div className="card-img-overlay">
          <h2 className="card-title card-body text-center align-middle welcome_text">Welcome to AIC Groups</h2>
        </div>
      </div>
    <div className="d-flex">
        <div className="container login">
            <div className="row justify-content-center">
                <div className="col-12  py-3">
                    <h2 className="text-center text-primary">Sign Up</h2>

                    <form className="my-2" onSubmit={handleSubmit(onSubmit)} noValidate>
                    <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      name="firstName"
                      placeholder="Bill"
                      className="form-control"
                      ref={register({
                        required: "First Name is a required"
                      })}
                    />
                    {errors.firstName && <span className="text-danger">{errors.firstName.message}</span>}
                    </div>
                    <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      name="lastName"
                      placeholder="Luo"
                      className="form-control"
                      ref={register({
                        required: "Last Name is required",
                        minLength: {
                          value: 2,
                          message: "Min length is 2"
                        }
                      })}
                    />
                    {errors.lastName && <span className="text-danger">{errors.lastName.message}</span>}
                    </div>
                    <div className="form-group">
                    <label htmlFor="username">User Name</label>
                    <input
                      name="username"
                      placeholder="username"
                      className="form-control"
                      ref={register({
                        required: "User Name is required",
                        minLength: {
                          value: 2,
                          message: "Min length is 6"
                        }
                      })}
                    />
                    {errors.username && <span className="text-danger">{errors.username.message}</span>}
                    </div>
                    <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      name="email"
                      placeholder="aic@hotmail.com"
                      type="text"
                      className="form-control"
                      ref={register({
                        required: "Email is required",
                        pattern: {
                          value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                          message: "Invalid email address"
                        }
                      })}
                    />
                    {errors.email && <span className="text-danger">{errors.email.message}</span>}
                    </div>
                    <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input name="password" type="password" className="form-control" id="password" ref={register({
                        required: "Required"
                    })} />
                    {errors.password && <span className="text-danger">Passward is required</span>}
                   </div>
                   <div className="form-group">
                   <label htmlFor="mobileNumber">Mobile Numaber</label>
                   <input
                     name="mobileNumber"
                     placeholder="99393932"
                     className="form-control"
                     ref={register({
                       required: "Mobile Number is required",
                       minLength: {
                         value: 2,
                         message: "Min length is 2"
                       }
                     })}
                   />
                   {errors.mobileNumber && <span className="text-danger">{errors.mobileNumber.message}</span>}
                   </div>
                   <div className="form-group">
                   <label className="reactSelectLabel">Role </label>
                   <Select
                      className="reactSelect"
                      name="select"
                      placeholder="Select Role"
                      value={values.selectedOption}
                      options={options}
                      onChange={handleSelectChange}
                 />
                 </div>
                   <div className="form-group">
                   <label>Activat User</label>
                   <input name="isActivated" className="ml-2" type="checkbox" ref={register} />
                   </div>
                    <div className="form-group mt-4">
                    <button className="btn btn-primary btn-block" type="submit">SignUp</button>
                </div>
                    </form>
                    <div className="d-flex mt-4">
                    <span className="mr-1">Already have an account? </span>
                            <a href="/login" className="nounderline"><div className="" >Login here</div></a>
                        </div>
                </div>
            </div>
        </div>
    </div>
  </div>
}

export default SignUpContainer;
import React, { useContext } from 'react';
import { useForm } from "react-hook-form";
import ProductTable from './products/components/ProductTable';
import listContext from '../context/product.context';
import { Link } from 'react-router-dom';

const CartPage = (props) => {
    const context = useContext(listContext);
    const { cart = [],token } = context;
    const products = cart.map(item => item.product);
    const getToken = () => localStorage.getItem('TOKEN_KEY');
    return <section >
        <div className="container py-5">
           <div className="container-fluid bg-primary py-2">
           <h3 className="font-weight-bold text-white">Cart Summary</h3>
           </div>
            <div className="my-5">
                {!!products.length ? <div>
                    <ProductTable products={products} />
                    {getToken() ? <div className="text-right mt-3">
                        <Link to="/proceed" className="btn btn-primary px-4">Place order</Link>
                    </div>:
                    <div className="text-right mt-3">
                    <a href="/login" className="btn btn-primary px-4">Place order</a>
                    </div>}                    
                </div> : <div>
                        <p>Cart is empty...</p>
                    </div>}
            </div>
        </div>
    </section>
}

export default CartPage;
import React from 'react'
import Fade from 'react-reveal/Fade';
import PageContainer from '../../../components/base/PageContainer'

export default function HomeCompanyInfo() {
    return <PageContainer className="home__company py-5">
        <div className="container py-5">
            <div className="row">
                <div className="col-8 lead home__company__box text-justify">
                    <Fade top>
                        <div className="p-3 ">
                            <h2 className="mb-2">Our Company</h2>
                            <p>Associated Instruments and Chemicals popularly known as AIC, is a Five Decade-old company that was founded in the year 1964 by Shri B.N. Kapila. Pioneers in dedicated service to the scientific community’s necessities by being a one-stop Shoppe for all laboratory requisites.</p>

                            <p>Aptly supported by a talented and dedicated staff, this selfless service has taken AIC to great heights in terms of market share and customer satisfaction. AIC’s customers have been with them since their inception, which clearly indicates the quality of unmatched service provided by AIC. Having offices at Chennai, Pondicherry, Vellore, and Bangalore the group caters to customers with a personalized touch.</p>

                            <button className="btn btn-outline-light shadow my-3 btn-lg">Explore more</button>
                        </div>
                    </Fade>
                </div>
                <Fade top>
                 <div className="col-4 mt-5 pt-5 ">
                            <img src={`/assets/images/founder/founder.webp`} alt="founder " className="product_img img-thumbnail mt-4" />
                        </div>
                </Fade>
            </div>
        </div>
    </PageContainer>
}

import React from 'react'
import Fade from 'react-reveal/Fade';

export default function HomeIntroInfo() {
    return <Fade>
        <section className="text-center py-5">
            <h2 className="text-capitalize">AIC ENTERPRISES</h2>
            <p>
                {[
                    "Wide Product Range",
                    "Efficient Customer Service",
                    "Wide Accessibility",
                    "In - Time Delivery",
                    "Qualified Technical Support"
                ].join(" | ")}
            </p>
        </section>
    </Fade>

}

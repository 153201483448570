import React, { useContext } from 'react'
import Fade from 'react-reveal/Fade';
import { useParams } from 'react-router-dom';
import { divisionsApi, http } from '../../../services/api.services';
import listContext from '../../../context/product.context';
import ProductTable from './ProductTable';
import Properties from '../../../components/base/Properties';

export default function ProductOverview(props) {
    const { products = [] } = props || []
    const [divisions, setDivisions] = React.useState([]);
    const { product_id } = useParams();
    const product = (products || []).filter((product) => product.code === product_id)[0] || products[0] || {}
    const [divisionsProducts, setDivisionsProducts] = React.useState([]);
    React.useEffect(() => {
        divisionsApi
            .index()
            .then(res => res.data)
            .then(divisions => {
                setDivisions(((divisions || []).filter(division => division.brand_code === product_id)))
            })
        const productUrl=Properties.productsUrl[product_id];
        http
            .get('/'+productUrl)
            .then(res => res.data)
            .then(products => {
                setDivisionsProducts(products || [])
            })
    }, [product_id])
    return <Fade top>
        <React.Fragment>
            {<div>
                <div className="container-fluid bg-dark py-5">
                    <div className="product__list__box">

                        <div className="container text-light py-5">
                            <h5 className="text-white mb-4 display-4 text-capitalize">{product.name}</h5>
                            {/* <div className="Heading h3">Overview</div> */}
                            <div className="overView lead">{product.description}</div>
                        </div>

                    </div>
                </div>
                <Description divisions={divisions} descriptions={product.descriptions} product={product} divisionsProducts={divisionsProducts} />
            </div>
            }
        </React.Fragment>
    </Fade>
}

export const Description = (props) => {
    const { descriptions = { division: [] }, product, divisions = [], divisionsProducts = [] } = props;
    console.log('descriptions', descriptions.division, product, divisions, divisionsProducts)

    const context = useContext(listContext);
    const { addToCart, cart = [] } = context;
    return (
        <div className="container py-5">
            <ul className="nav clearRoom mb-5" id="myTab" role="tablist">
                {divisions.map((division, index) => <React.Fragment key={index}>
                    <li className="nav-item">
                        <a className={`nav-link font-weight-bold ${index === 0 ? 'active' : ''}`} id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">{division.name}</a>
                    </li>
                </React.Fragment>
                )}
            </ul>
            <ProductTable products={divisionsProducts} />
        </div>
    )
}
import React from 'react'
import Fade from 'react-reveal/Fade';

export default function PageContainer(props) {
    const className = "container-fluid " + (props.className || " ");

    return <Fade>
        <section {...props} className={className}>
            { props.children || [] }
        </section>
    </Fade>

}

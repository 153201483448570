import React from 'react'

export default function HomeProductDetail(props) {
    const { product = {} } = props;
    return <div className="row text-white align-items-center">
        <div className="col-md-7 col-12">
            <h2 className="text-white font-weight-bold mb-3">{product.title}</h2>
            <p className="text-white">{product.info}</p>

            <a href="/products"><button className="btn btn-outline-light shadow my-3 btn-lg">Explore more</button></a>
        </div>
        <div className="col-md-5 col-12">
            <img src={`/assets/images/products/${product.image}`} alt="info" className="product_img img-thumbnail" />
        </div>
    </div>
}

import React, { Component } from 'react'
import PageContainer from '../base/PageContainer'
import HeaderLogo from './components/HeaderLogo'
import HeaderMenu from './components/HeaderMenu'

import './HeaderContainer.css';

export default class HeaderContainer extends Component {
    render() {
        return <PageContainer className="py-3 shadow header__container" style={HeaderContainerStyles}>
            <div className="container d-flex align-items-center">
                <HeaderLogo />
                <HeaderMenu />
            </div>
        </PageContainer>
    }
}

const HeaderContainerStyles = {
    background: '#fdfdfd'
}

import React from 'react'
import HomeSlider from './components/HomeSlider'
import HomeIntroInfo from './components/HomeIntroInfo'
import HomeProducts from './components/HomeProducts'

import './HomeContainer.css'
import HomeMissionAndVision from './components/HomeMissionAndVision'
import HomeStats from './components/HomeStats'
import HomeCompanyInfo from './components/HomeCompanyInfo'
import HomeClients from './components/HomeClients'

export default function HomeContainer() {
    return <section className="pt-4">
        <HomeSlider />
        <HomeIntroInfo />
        <HomeProducts />
        <HomeMissionAndVision />
        <HomeStats />
        <HomeCompanyInfo />
        <HomeClients />
    </section>
}

import React from 'react';

const Properties={
    productsUrl:{
        ThermoFisherScientific001:"themoFisherItems",
        SRL001 :"srlItems",
        Axiva001 : "avivaItems",
        Merck001 : "merckItems",
        Qiagen001 : "qiagenItems",
        Eutech001  : "eutechItems",
        VAI001 : "vaiItems",
        MagGenome001  : "maggenomeItems",
        Waters001  : "watersItems",
        Tarsons001  : "tarsonsItems",
        Genaxy001  : "genaxyItems",
        Borosil001   : "borosilItems",
        KimberlyClark001  : "kimberlyItems",
        Honeywell001 : "honeywellItems",
        Invitrogen001  : "invitrogenItems",
        SterimedInc001  : "sterimedItems",
        Riviera001 : "rivieraItems",
        GE001 : "geItems",
        HPLC001 : "hplcItems",
    }
}
export default Properties;
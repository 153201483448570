import axios from 'axios';
import createRepository from './api/repository'

//export const BASE_URL = 'http://localhost:3333'
export const BASE_URL = 'http://35.209.108.67'
export const API_URL = `${BASE_URL}/api/v1/`

export const httpClient = axios.create({
    baseURL:API_URL,
    headers: {
        "Content-Type": "application/json",
    }
});

// HTTP METHODs (CRUD)
const repositoryWithAxios = createRepository(httpClient);
export const productsApi = repositoryWithAxios('products');
export const brandsApi = repositoryWithAxios('brands');
export const divisionsApi = repositoryWithAxios('divisions');
export const categoriesApi = repositoryWithAxios('categories'); //  `${BASE_URL}/api/v1/` + categories; -> 1. index(), 2. show(), 3. create(), 4. update(), 5. delete()
export const registerApi = repositoryWithAxios('register');
export const signInApi = repositoryWithAxios('signIn'); 
export const logoutApi = repositoryWithAxios('logout'); 
export const currentUserApi = repositoryWithAxios('me');  
export const sendOrderApi = repositoryWithAxios('orders');  

export const http = axios.create({
    baseURL:API_URL,
    headers: {
        "Content-Type": "application/json",
    }
});

export const httpClientAuth=(token) => axios.create({
    baseURL:API_URL,
    headers: {
        "Authorization" : `Bearer ${token}`
    }
});

import React from 'react'
import Carousel from '@brainhubeu/react-carousel';

export default function HomeProductSlider(props) {
    const { products = [] } = props;
    const images = (products || []).map(product => product.image)
    return <Carousel
        slidesPerPage={6}
        autoPlay={2000}
        animationSpeed={3000}
        arrows
        infinite
    >
        {(images || []).map((image, idx) => <div  key={idx} onClick={() => props.onClick(products[idx])} className="cursor p-3 bg-white border m-2"><img alt={"Slider " + (idx + 1)} src={`/assets/images/products/${image}`}  /></div>)}
    </Carousel>
}

import React from 'react'
import Fade from 'react-reveal/Fade';
import ProductOverview from './ProductOverview';
import { brandsApi } from '../../../services/api.services';

export default function ThermoFisher() {
  const [products, assignProducts] = React.useState([]); // -> [value, valueSetterFunction];
    /**
     *  const numbers = [1,2,3];
     *  const [one, two] = numbers;
     */

    React.useEffect(() => {
        brandsApi
            .index()
            .then(res => res.data)
            .then(products => {
                // console.log(":: ProductsContainer ::", products)
                assignProducts(products);
                localStorage.setItem("PRODUCTS", JSON.stringify(products));
            })


    }, [])
  return <Fade top>
    <ProductOverview products={products} />
  </Fade >
}


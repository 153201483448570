import React from 'react'
import PageContainer from '../../../components/base/PageContainer'
import HomeProductDetail from './HomeProductDetail';
import HomeProductSlider from './HomeProductSlider';

export default function HomeProducts() {
    const [product, setProduct] = React.useState(homeProductsInfo[0]);
    return <PageContainer className="py-5 home__products">
        <div className="container py-5">
            <HomeProductDetail product={product} />
            <HomeProductSlider products={homeProductsInfo} onClick={setProduct} />
        </div>
    </PageContainer>
}

// const homeProductsInfo = (new Array(5).fill(1)).map((n, idx) => ({
//     idx: idx + 1,
//     info: `Product ${idx + 1} Being in the Pharma industry for five decades now, we take pride in showcasing a multitude of products ranging from 50000 to 1L and maintaining one of the largest inventories. We also operate across India at different locations namely, Bengaluru, Chennai, Pondicherry and Vellore. Our operations network enables us to provide easy accessibility and a well-connected dealership to our patrons. What more? We also have individual consultation along with on-time deliveries to ensure businesses run smoothly!`,
//     image: `http://placehold.it/640x380.png?text=Product+${idx + 1}`
// })
// )

const homeProductsInfo = [
    {
        id: 1,
        title: '1300 Series A2 Biological Safety Cabinet Packages',
        info: `Being in the Pharma industry for five decades now, we take pride in showcasing a multitude of products ranging from 50000 to 1L and maintaining one of the largest inventories. We also operate across India at different locations namely, Bengaluru, Chennai, Pondicherry and Vellore. Our operations network enables us to provide easy accessibility and a well-connected dealership to our patrons. What more? We also have individual consultation along with on-time deliveries to ensure businesses run smoothly!`,
        image: '1300 Series A2 Biological Safety Cabinet Packages.jpg'

    },
    {
        id: 2,
        title: 'ABDOS CRYOVIALS',
        info: `Being in the Pharma industry for five decades now, we take pride in showcasing a multitude of products ranging from 50000 to 1L and maintaining one of the largest inventories. We also operate across India at different locations namely, Bengaluru, Chennai, Pondicherry and Vellore. Our operations network enables us to provide easy accessibility and a well-connected dealership to our patrons. What more? We also have individual consultation along with on-time deliveries to ensure businesses run smoothly!`,
        image: 'ABDOS CRYOVIALS.jpg'

    },
    {
        id: 3,
        title: 'Aczet Weighing Balance',
        info: `Being in the Pharma industry for five decades now, we take pride in showcasing a multitude of products ranging from 50000 to 1L and maintaining one of the largest inventories. We also operate across India at different locations namely, Bengaluru, Chennai, Pondicherry and Vellore. Our operations network enables us to provide easy accessibility and a well-connected dealership to our patrons. What more? We also have individual consultation along with on-time deliveries to ensure businesses run smoothly!`,
        image: 'Aczet Weighing Balance.jpg'

    },
    {
        id: 4,
        title: 'Borosil Laboratory Glass',
        info: `Being in the Pharma industry for five decades now, we take pride in showcasing a multitude of products ranging from 50000 to 1L and maintaining one of the largest inventories. We also operate across India at different locations namely, Bengaluru, Chennai, Pondicherry and Vellore. Our operations network enables us to provide easy accessibility and a well-connected dealership to our patrons. What more? We also have individual consultation along with on-time deliveries to ensure businesses run smoothly!`,
        image: 'Borosil Laboratory Glass.jpg'

    },
    {
        id: 1,
        title: '1300 Series A2 Biological Safety Cabinet Packages',
        info: `Being in the Pharma industry for five decades now, we take pride in showcasing a multitude of products ranging from 50000 to 1L and maintaining one of the largest inventories. We also operate across India at different locations namely, Bengaluru, Chennai, Pondicherry and Vellore. Our operations network enables us to provide easy accessibility and a well-connected dealership to our patrons. What more? We also have individual consultation along with on-time deliveries to ensure businesses run smoothly!`,
        image: '1300 Series A2 Biological Safety Cabinet Packages.jpg'

    },
    {
        id: 4,
        title: 'Countess™ II FL Automated Cell Counter',
        info: `Being in the Pharma industry for five decades now, we take pride in showcasing a multitude of products ranging from 50000 to 1L and maintaining one of the largest inventories. We also operate across India at different locations namely, Bengaluru, Chennai, Pondicherry and Vellore. Our operations network enables us to provide easy accessibility and a well-connected dealership to our patrons. What more? We also have individual consultation along with on-time deliveries to ensure businesses run smoothly!`,
        image: 'Countess™ II FL Automated Cell Counter.jpg'

    },
    {
        id: 5,
        title: 'Duran laboratory Glassware',
        info: `Being in the Pharma industry for five decades now, we take pride in showcasing a multitude of products ranging from 50000 to 1L and maintaining one of the largest inventories. We also operate across India at different locations namely, Bengaluru, Chennai, Pondicherry and Vellore. Our operations network enables us to provide easy accessibility and a well-connected dealership to our patrons. What more? We also have individual consultation along with on-time deliveries to ensure businesses run smoothly!`,
        image: 'Duran laboratory Glassware.jpg'

    }
]


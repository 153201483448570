import React ,{ useContext,useEffect, useState }from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,useHistory,Redirect 
} from "react-router-dom";
import './App.css';
import HeaderContainer from './components/header/HeaderContainer';
import HomeContainer from './containers/home/HomeContainer';
import FootContainer from './components/footer/FootContainer';
import AboutContainer from './containers/about/AboutContainer';
import ProductsContainer from './containers/products/ProductsContainer';
import ContactContainer from './containers/contact/ContactContainer';
import HeaderEnquiryModal from './components/header/components/HeaderEnquiryModal';
import ThermoFisher from './containers/products/components/ThermoFisher';
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import LoginContainer from './containers/LoginContainer';
import CartPage from './containers/CartPage';
import AddProduct from './containers/addProduct/AddProduct';
import SignUpContainer from './containers/SignUpContainer';
import listContext from './context/product.context';
import ProceedContainer from './containers/cart/components/Proceed';

function App() {
  const context = useContext(listContext);
  const {message,path}= context;
  const [isMessage,setIsMessage] = useState(!!message)
  const [isError,setIsError] = useState(false)
  useEffect(() => {
      const timeoutID = window.setTimeout(() => {
       
        if(isMessage){
          setIsMessage(false)
        }
    }, 3000);
    return () => window.clearTimeout(timeoutID );
    },[isMessage]);
    useEffect(() => {
       console.log('context',context)
       setIsMessage(!!message)
      },[context]);
  console.log('path',path)
  // console.log('error',isError)
  console.log('isError',message)
  //   {
  //     !localStorage.getItem('TOKEN_KEY') ? <Redirect from='/' to='/login' /> : ''
  //  }
  return (
    <Router>

      <div className="App">
        {isMessage && <div id="message">
          <div >
            <div id="inner-message" className={isError?"alert alert-success":"alert alert-danger"}>
              <button type="button" className="close" data-dismiss="alert">&times;</button>
              {message}
        </div>
          </div>
        </div>}
        <Route exact path="/login">
              <LoginContainer />
            </Route>
        <Route exact path="/signUp">
            <SignUpContainer />
          </Route>
        { path !== '/login' && path !== '/signUp' &&  <div> 
        <HeaderContainer />
        <main className="view__main">
          <Switch>
            <Route exact path="/home">
              <HomeContainer />
            </Route>
            <Route exact path="/products">
              <ProductsContainer />
            </Route>
            <Route exact path="/about-us">
              <AboutContainer />
            </Route>
            <Route exact path="/contact-us">
              <ContactContainer />
            </Route>
            <Route exact path="/add-products">
              <AddProduct/>
            </Route>
            <Route exact path="/proceed">
              <ProceedContainer/>
            </Route>
            <Route exact path="/products/:product_id">
              <ThermoFisher />
            </Route>
            <Route exact path="/">
              <HomeContainer />
            </Route>
            <Route exact path="/cart">
              <CartPage />
            </Route>
          </Switch>
        </main>
        <FootContainer />
        <HeaderEnquiryModal />
        </div>
        }
        <div>
          <ScrollUpButton
            StopPosition={0}
            ShowAtPosition={150}
            AnimationDuration={500} />
        </div>
      </div>
    </Router>

  );
}

export default App;

import React from 'react'
import CountUp from 'react-countup';
import PageContainer from '../../../components/base/PageContainer'

export default function HomeStats() {
    return <PageContainer className="py-5 shadow home__stats">
        <div className="container">
            <div className="row py-5">
                {stats.map((stat, idx) => <div key={idx} className="col-md-3 text-center">
                    <h4 className="display-4 font-weight-bold home__stat">
                        <CountUp start={0} duration={6}  end={stat.count} />
                        {stat.suffix}
                    </h4>
                    <p className="lead mb-0">{stat.info}</p>
                </div>)}
            </div>
        </div>
    </PageContainer>
}

const stats = [
    { count: 5000, info: 'Years of Experience', suffix: '+' },
   /*  { count: 10, info: 'Awards', suffix: '+' }, */
   { count: 100, info: 'Brands', suffix: '+' },
    { count: 1000, info: 'Happy Clients', suffix: '+' },
    { count: 15, info: 'Partners', suffix: '+' },
]

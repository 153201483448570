import React from 'react'


const AddProductInputField =(props) => {
    console.log('props',props)
    return <div class="form-group row">
    <label for="inputEmail3" class="col-sm-2 col-form-label">{props.name}</label>
    <div class="col-sm-10">
     <input type="text" class="form-control" id="inputEmail3" placeholder={props.name} />
     </div>
   </div>
}
export default  AddProductInputField;

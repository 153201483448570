import React from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

export default function ProductsList({ products, isMenu = false }) {
    console.log(":: ProductsList ::", products);
    return <Fade top>
        <div className="row product__list">
            {products && products.map((product, idx) => <div key={idx} className="col-md-3 pb-4">
                <div className="product__list__box cursor card shadow h-100">
                    <Link className="card-body d-flex" to={`/products/${product.code}`} rel="noopener noreferrer" >
                        <h5 className="mb-0 text-capitalize">
                            {isMenu && <img src={ !!product.image ? `/assets/images/brands/${product.image}` : `//placehold.it/28x28`} alt={product.name} className="rounded mr-3" />}
                            {product.name}
                        </h5>
                    </Link>
                </div>
            </div>)}
        </div>
    </Fade>
}
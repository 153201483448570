// Provide nuxt-axios instance to use same configuration across the whole project
// I've used typical CRUD method names and actions here
const client = $axios => resource => ({
    index() {
        return $axios.get(`${resource}`)
    },

    show(id) {
        return $axios.get(`${resource}/${id}`)
    },

    create(payload) {
        return $axios.post(`${resource}`, payload)
    },

    update(id, payload) {
        return $axios.post(`${resource}/${id}`, payload)
    },

    delete(id) {
        return $axios.delete(`${resource}/${id}`)
    },

    get(url) {
        return $axios.get(`${resource}/${url}`)
    }
});

export default client;

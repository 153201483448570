import React from 'react'
import { FaMapMarkerAlt } from "react-icons/fa";

export default function ContactLocations() {
    return <div className="col contact__locations text-white p-5">
        <h2 className="font-weight-bold text-white">Contact us</h2>
        {locations.map((location, idx) => <LocationDetail key={idx} location={location} border={idx !== (locations.length - 1)} />)}
    </div>
}

const LocationDetail = ({ location, border }) => <div className={`contact__locations__item py-4 ${border ? 'border-bottom' : ''}`}>
    <div className="d-flex align-items-start">
        <FaMapMarkerAlt className="mr-3 locationIcon"/>
        <div>
            <h4>{location.location}</h4>
            <p className="mb-0">{location.address}</p>
        </div>
    </div>
</div>


const locations = [
    { location: 'Bangalore', address: 'V79A & V79B, 1st A Main, 2nd Stage, Peenya, Bengaluru, Karnataka 560058' },
    { location: 'Chennai', address: '56, Langs Garden Road, Pudupet,xxChennai - 600 002.' },
    { location: 'Vellore', address: 'V79A & V79B, 1st A Main, 2nd Stage, Peenya, Bengaluru, Karnataka 560058' },
    { location: 'Pondicherry', address: 'V79A & V79B, 1st A Main, 2nd Stage, Peenya, Bengaluru, Karnataka 560058' },
]
import React from 'react'
import {
    Link
} from "react-router-dom";
import Fade from 'react-reveal/Fade';
import { brandsApi } from '../../../services/api.services';

import ProductsList from '../../../containers/products/components/ProductsList'

export default function HeaderMenuNavigation() {
    const pathname = window.location.pathname;
    const currentPath = (pathname || "/").substr(1) || "home"
    const [activeMenu, setActiveMenu] = React.useState(currentPath);
    return <nav className="header__menu__navigation ">
        <ul className="d-flex w-100 list-unstyled justify-content-around mb-0">
            {headerMenuItems.map((menu, idx) => <li className={!!menu.subMenu ? 'has_menu' : ''} key={idx}>
                <Link
                    className={activeMenu === menu.link ? 'active' : ''}
                    key={idx}
                    onClick={() => setActiveMenu(menu.link)}
                    to={`/${menu.link}`}>
                    {menu.name}
                </Link>
                {!!menu.subMenu && menu.subMenu()}
            </li>)}
        </ul>
    </nav>
}

const ProductSubMenu = () =>{
    const [products, assignProducts] = React.useState([]); // -> [value, valueSetterFunction];
    /**
     *  const numbers = [1,2,3];
     *  const [one, two] = numbers;
     */

    React.useEffect(() => {
        brandsApi
            .index()
            .then(res => res.data)
            .then(products => {
                // console.log(":: ProductsContainer ::", products)
                assignProducts(products);
            })
    }, []);

return (<Fade top>
    <div className="sub__menu container shadow">
        <ProductsList products={products} isMenu={true} />
    </div>
</Fade>)}

const headerMenuItems = [
    { name: 'Home', link: 'home' },
    { name: 'Products', link: 'products', subMenu: () => <ProductSubMenu /> },
    { name: 'Contact us', link: 'contact-us' },
    { name: 'About us', link: 'about-us' },
    { name: 'Add product', link: 'add-products' },
];

